import { Typography } from '@material-ui/core';
import React from 'react';

import { Event } from '../../model/tsp/event';

interface EventTitleProps {
  event: Event;
  isMultipleEvents?: boolean;
}

export const EventTitle = ({
  event: { title, displayStartDate },
  isMultipleEvents = false,
}: EventTitleProps) => {
  return (
    <>
      <Typography variant='h6'>{isMultipleEvents ? 'Multiple Events' : title}</Typography>
      <Typography variant='subtitle1'>{displayStartDate}</Typography>
    </>
  );
};
