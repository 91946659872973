import { combineReducers } from '@reduxjs/toolkit';
import settingsReducer from './slices/settings-slice';
import userReducer from './slices/user-slice';
import eventReducer from './slices/event-slice';
import accessPackageReducer from './slices/access-package-slice';
import orderReducer from './slices/order-slice';
import ticketReducer from './slices/ticket-slice';
import modalConfirmationSlice from './slices/modalSlice';

const appReducer = combineReducers({
  settings: settingsReducer,
  user: userReducer,
  event: eventReducer,
  accessPackage: accessPackageReducer,
  order: orderReducer,
  ticket: ticketReducer,
  modalConfirmation: modalConfirmationSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'settings/reduxReset') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type RootReducerType = typeof rootReducer;

export default rootReducer;
