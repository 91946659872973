import { makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles((theme) => ({
  checkIconSuccess: {
    color: theme.palette.success.main,
  },
  checkIcon: {
    color: 'rgba(0, 0, 0, 0.25)',
  },
}));

interface CheckCircleProps {
  checked: boolean;
}

export const CheckCircle = ({ checked }: CheckCircleProps): ReactElement => {
  const classes = useStyles();

  return (
    <>
      {checked === true ? (
        <CheckCircleIcon className={classes.checkIconSuccess} />
      ) : (
        <CircleUnchecked className={classes.checkIcon} />
      )}
    </>
  );
};
