import React, { FC, ReactNode } from 'react';
import { Box, Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import { FONT_BOLD } from '../../model/constants';

interface ButtonIconStylesProps {
  color: string;
  borderColor: string;
  backgroundColor: string;
  marginTop: string;
}

const useButtonStyles = makeStyles<Theme, ButtonIconStylesProps>(() => ({
  btnStyle: {
    marginTop: ({ marginTop }) => marginTop,
    '&.MuiButtonBase-root': {
      fontFamily: FONT_BOLD,
      color: ({ color }) => color,
      backgroundColor: ({ backgroundColor }) => backgroundColor,
      boxShadow: 'none',
      borderColor: ({ borderColor }) => borderColor,
      textTransform: 'none',
    },
  },
}));

interface ButtonIconProps extends ButtonProps {
  label: string;
  btnStyle?: {
    color?: string;
    borderColor?: string;
    backgroundColor?: string;
    marginTop?: string;
  };
  children?: { children?: ReactNode | undefined };
}

const ButtonIcon: FC<ButtonIconProps> = ({ label, btnStyle, children, ...props }) => {
  const { disabled } = props;
  const btnClass = useButtonStyles({
    color: btnStyle?.color || '#000',
    borderColor: btnStyle?.borderColor || '#2196F3',
    backgroundColor: btnStyle?.backgroundColor || '#EFEFEF',
    marginTop: btnStyle?.marginTop || '1rem',
  });
  const btnDisabled = useButtonStyles({
    color: '#AAA',
    borderColor: '#AAA',
    backgroundColor: '#EFEFEF',
    marginTop: '0',
  })

  return (
    <Button fullWidth type='submit' variant='outlined' size='medium' className={disabled ? btnDisabled.btnStyle : btnClass.btnStyle} {...props}>
      <Box display='flex' flexDirection='row'>
        <Box component='span' marginRight={'0.75rem'}>
          {children}
        </Box>
        {label}
      </Box>
    </Button>
  );
};

export default ButtonIcon;
