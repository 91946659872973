import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ModalConfirmationState = {
  isOpened: boolean;
  loading: boolean;
  args: string[];
  modalType: string;
};

const initialState: ModalConfirmationState = {
  isOpened: false,
  loading: false,
  args: [],
  modalType: '',
};

export const modalConfirmationSlice = createSlice({
  name: 'modalConfirmation',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.isOpened = true;
      state.modalType = action.payload;
    },
    closeModal: (state) => {
      state.isOpened = false;
      state.modalType = '';
    },
    startLoadingModal: (state) => {
      state.loading = true;
    },
    stopLoadingModal: (state) => {
      state.loading = false;
    },
    setArgs: (state, { payload }: PayloadAction<string[]>) => {
      state.args = payload;
    },
  },
});

export default modalConfirmationSlice.reducer;

// ------------ ACTIONS ------------

export const { openModal, closeModal, startLoadingModal, stopLoadingModal, setArgs } = modalConfirmationSlice.actions;
