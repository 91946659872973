import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiError } from '../../model/common-types';
import { GenericMessage } from '../../model/constants';
import { ThemeSettings } from '../../model/theme-settings';
import {Ticket, TicketResponse} from '../../model/tsp/ticket';
import { apiGetTicketsByEventId, apiGetTicktsTypes, apiLoadSettings } from '../../service/api-service';
import { checkinsDBService, db } from '../../service/db-service';
import { getHexColorCss } from '../../util/common-utils';
import { retrieveTicketsByEventId, syncPendingTickets } from './ticket-thunks';
import { setEventLastUpdate } from '../slices/ticket-slice';
import { setLoadingManually } from '../slices/settings-slice';

export const loadSettings = createAsyncThunk<ThemeSettings, void, { rejectValue: ApiError }>(
  'loadSettings',
  async (_: void, thunkApi) => {
    try {
      const res = await apiLoadSettings();
      if (res.success) {
        return {
          headerBackground: getHexColorCss(res.data.mainTopBarColor),
          logoUrl: res.data.adminHeaderPicture,
          siteName: res.data.siteName,
          accentColor: getHexColorCss(res.data.ticketClaimEmailHeaderBackground),
        } as ThemeSettings;
      } else {
        return thunkApi.rejectWithValue({ code: 400, message: res.message });
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);

export const setOfflineMode = createAsyncThunk<string[], string[], { rejectValue: ApiError }>(
  'setOfflineMode',
  async (eventIds: string[], thunkApi) => {
    try {
      console.log("ha");
      console.log(eventIds);
      if (eventIds.length === 0) {
        return [];
      }
      //const state = thunkApi.getState() as RootState;
      for (const eventId of eventIds) {
        console.log(eventId);
        thunkApi.dispatch(setLoadingManually(true));
        //const event = (await state.event.events.find((ev) => ev.id === eventId)) as Event;
        // await db.initializeOffline(event);
        const res = await apiGetTicketsByEventId(eventId, undefined, async (res: TicketResponse): Promise<void> => {
          await db.tickets.bulkAdd(res.data.map(t => transformDataTicket(t)));
        });
        const ticketTypes = await apiGetTicktsTypes(eventId);
        const mapTicketTypes = ticketTypes.data.map((type) => ({ id: type.id, name: type.name, eventId }));
        db.offlineTicketTypes.bulkAdd(mapTicketTypes);

        const lastUpdate = Math.max(0, ...res.data.map((ticket) => parseInt(ticket.lastUpdate)));
        thunkApi.dispatch(setEventLastUpdate({ eventId, lastUpdate }));

        thunkApi.dispatch(retrieveTicketsByEventId(eventId));
      }

      thunkApi.dispatch(existsPendingsWithErrors());
      thunkApi.dispatch(setLoadingManually(false));

      return eventIds;
    } catch (error) {
      thunkApi.dispatch(setLoadingManually(false));
      return thunkApi.rejectWithValue({
        code: 400,
        message: error?.message || GenericMessage.UnknownError,
      } as ApiError);
    }
  },
);

const transformDataTicket = (ticket: Ticket): Ticket => {
  const {
    billing_firstName,
    billing_lastName,
    firstName,
    lastName,
    partyMember,
    partyMemberLastName
  } = ticket;
  const partyMemberDB = partyMember || firstName || billing_firstName;
  const partyMemberLastNameDB = partyMemberLastName || lastName || billing_lastName;
  const allFirstNames = partyMember + ' ' +  firstName + ' ' + billing_firstName;
  const allLastNames = partyMemberLastName + ' ' +  lastName + ' ' + billing_lastName;
  const fullName = getAllWords(allFirstNames + ' ' + allLastNames);
  return {
    ...ticket,
    partyMember: partyMemberDB,
    partyMemberLastName: partyMemberLastNameDB,
    fullName
  }
};

const getAllWords = (text: string) => {
  const allWordsIncludingDups = text.split(' ');
  const wordSet = allWordsIncludingDups.reduce(function (prev, current) {
    prev[current] = true;
    return prev;
  }, {});
  return Object.keys(wordSet);
}

export const existsPendingsWithErrors = createAsyncThunk<number, void, { rejectValue: ApiError }>(
  'existsPendingsWithErrors',
  async () => {
    return await checkinsDBService.existsPendingsWithErrors();
  },
);

export const setOnlineMode = createAsyncThunk<string[], string[], { rejectValue: ApiError }>(
  'setOnlineMode',
  async (eventIds: string[], thunkApi) => {
    try {
      await thunkApi.dispatch(syncPendingTickets());

      return eventIds;
    } catch (error) {
      return thunkApi.rejectWithValue(error as ApiError);
    }
  },
);
