import React, { ReactChild } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAppSelector } from '../../store';

interface AuthRouteProps {
  path: string;
  child: ReactChild;
  exact?: boolean;
}

export const AuthRoute = ({ path, child, exact = false }: AuthRouteProps): JSX.Element => {
  const auth = useAppSelector((state) => state.user.isAuthenticated);
  return auth ? (
    <Route exact={exact} path={path}>
      {child}
    </Route>
  ) : (
    <Redirect to='/login' />
  );
};
