import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { LoaderModal } from '../display/loader-modal';
import { CheckInOverrideModal } from '../display/check-in-override-modal';
import { useDispatch } from 'react-redux';
import { PlayFunction } from 'use-sound/dist/types';
import { useAppSelector } from '../../store';
import { cleanCheckin } from '../../store/slices/ticket-slice';
import { checkinTicket } from '../../store/thunks/ticket-thunks';
import { KeyboardReader } from '../display/keyboard-reader';
import {
  selectAccessPackages,
  selectEventsToScan,
  selectIsMultipleEvents,
  selectSelectedEvent
} from '../../store/selectors';
import { selectSelectedTicketTypes } from '../../store/slices/selectors';
import SuccessNotification from '../display/success-notification';
import ConfirmationModal from '../display/confirmation-modal';
import { openModal } from '../../store/slices/modalSlice';
import InfoIcon from '@material-ui/icons/Info';
import { FONT_BOLD, OrderMessage } from '../../model/constants';
import { ProcessState } from '../../model/tsp/order';
import { Ticket } from '../../model/tsp/ticket';
import {
  getEventInPackageWithAvailable,
  getUsedScansAndAvailable,
  inAccessPackage,
  isEventInAccessPackage
} from "../../util/access-package-utils";
import EventHeader from "../display/event-header";

const useStyles = makeStyles((theme: Theme) => ({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  padding: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  successContainer: {
    width: '100%',
    padding: '5px',
    borderRadius: '3px',
    backgroundColor: theme.palette.success.main,
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    gap: '5px',
    color: '#ffffff',
    boxShadow: 'none',
    fontSize: '12px',
    fontWeight: 'bold',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  successIcon: {
    marginRight: '1.5rem',
    marginTop: '2px',
  },
  detailsContainer: {
    marginLeft: '1.5rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
  },
  checkinText: {
    fontSize: '15px',
  },
  checkInButton: {
    border: '1px solid rgba(0, 0, 0, 0.87)',
    fontFamily: FONT_BOLD,
    textTransform: 'initial',
  },
  infoIcon: {
    marginRight: '0.25rem',
  },
  successMessage: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    color: 'green',
    fontWeight: 'bold',
  },
}));

interface LaserScanPageProps {
  playSuccess: PlayFunction;
  playError: PlayFunction;
}

export const LaserScanPage: FC<LaserScanPageProps> = ({ playError, playSuccess }) => {
  const classes = useStyles();
  const { eventId } = useParams<{ eventId: string }>();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedEvent = useAppSelector(selectSelectedEvent);
  const toScan = useAppSelector(selectEventsToScan);
  const accessPackages = useAppSelector(selectAccessPackages);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const { loading, errorMessage, checkin } = useAppSelector((state) => state.ticket);
  const selectedOrder = useAppSelector((state) => state.order.selectedOrder);
  const { offlineMode } = useAppSelector((state) => state.settings);
  const [overrideModalOpen, setOverrideModalOpen] = useState(false);
  const [barCodeSelected, setBarCodeSelected] = useState('');
  const [allTicketsToScan, setAllTicketsToScan] = useState<Ticket[]>([]);
  const [totalTicketsToScan, setTotalTicketsToScan] = useState<number>(0);
  const eventSelectedTicketTypes = useAppSelector(selectSelectedTicketTypes);
  const [checkInEntireOrder, setCheckInEntireOrder] = useState(false);
  let ticketName = '';
  let orderTicket;
  let scanned = '';

  useEffect(() => {
    dispatch(cleanCheckin());

    return () => {
      dispatch(cleanCheckin());
    };
  }, []);

  useEffect(() => {
    if (checkin?.valid && !checkInEntireOrder) {
      playSuccess();
    }
  }, [checkin, checkInEntireOrder]);

  useEffect(() => {
    if (errorMessage) {
      playError();
    }
  }, [errorMessage]);

  useEffect(() => {
    if (selectedOrder) {
      const orderTicket = selectedOrder?.tickets?.data?.find((ot) => ot.barcode === barCodeSelected);
      if (orderTicket && inAccessPackage(orderTicket)) {
        const otherPendingTickets: Ticket[] = [];
        const otherTickets = selectedOrder?.tickets?.data?.filter((ot) => ot.barcode !== barCodeSelected);
        otherTickets.forEach((ticket) => {
          const { usedScans, availableScans } = getUsedScansAndAvailable(accessPackages, ticket, eventId);
          if (availableScans != usedScans) {
            otherPendingTickets.push(ticket);
          }
        });
        setAllTicketsToScan(otherPendingTickets);
        setTotalTicketsToScan(otherPendingTickets.length);
      } else {
        const ticketsToScan = selectedOrder?.tickets.data.filter(
          (ticket) =>
            ticket.processState === ProcessState.Purchased &&
            parseInt(ticket.availableScans) !== parseInt(ticket.usedScans),
        );
        setAllTicketsToScan(ticketsToScan);
        setTotalTicketsToScan(ticketsToScan?.filter((ticket) => ticket.barcode !== barCodeSelected).length);
      }
    }
  }, [selectedOrder]);

  const checkinScanFirstTime = (barCode: string) => {
    setBarCodeSelected(barCode);
    checkinScan(barCode);
  };

  const checkinScan = (barCode: string) => {
    const selectedTicketTypes = (eventSelectedTicketTypes[eventId] || []).map((selected) => selected.id);

    const query = {
      barCode,
      eventIds: getEventsToCheckin(),
      ticketTypeIds: selectedTicketTypes,
      isMultipleEvents: isMultipleEvents,
    };

    if (barCode?.length > 0) {
      setOverrideModalOpen(false);
      dispatch(checkinTicket(query));
      window.scrollTo(0, 80);
    }
    setCheckInEntireOrder(false);
  };

  const checkinEntireOrder = (barCode: string) => {
    const selectedTicketTypes = (eventSelectedTicketTypes[eventId] || []).map((selected) => selected.id);

    const query = {
      barCode,
      eventIds: getEventsToCheckin(),
      ticketTypeIds: selectedTicketTypes,
      isMultipleEvents: isMultipleEvents,
    };

    if (barCode?.length > 0) {
      setOverrideModalOpen(false);
      dispatch(checkinTicket(query));
    }
  };

  const getEventsToCheckin = () => {
    if (isEventInAccessPackage(accessPackages, eventId)) {
      return [eventId];
    }
    return toScan.map((event) => event.id);
  };

  const scanAllTickets = async () => {
    try {
      if (allTicketsToScan) {
        for (const ticket of allTicketsToScan) {
          if (barCodeSelected !== ticket.barcode) {
            await checkinEntireOrder(ticket.barcode);
          }
        }
      }
      window.scrollTo(0, 80);
      setCheckInEntireOrder(true);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const confirmCheckIn = () => {
    dispatch(openModal('scanAll'));
  };
  if (checkin?.valid) {
    orderTicket = selectedOrder?.tickets?.data?.find((ot) => ot.barcode === checkin?.barcode);
    if (orderTicket) {
      const eventId = getEventInPackageWithAvailable(accessPackages, orderTicket, toScan.map((event) => event.id));
      const { usedScans, availableScans } = getUsedScansAndAvailable(accessPackages, orderTicket, eventId);

      ticketName =
        orderTicket.firstName === 'Box' && orderTicket.lastName == 'Office'
          ? 'Box Office'
          : orderTicket.partyMember + ' ' + orderTicket.partyMemberLastName;

      if (orderTicket?.scanType === 'package' && availableScans && usedScans) {
        scanned = ` - ${usedScans}/${availableScans}`;
      }

      if (checkInEntireOrder) {
        ticketName = OrderMessage.EntireOrderChecked;
      }
    }
  }

  const label = checkInEntireOrder
    ? `${checkin?.barcode}<br/>${ticketName}`
    : `${checkin?.barcode}<br/>${ticketName} Checked In ${scanned}`;

  const goToScannedEvent = () => {
    history.push(`/event/${eventId}/order/${checkin.orderId}/barcode/${checkin.barcode}`);
  };

  return (
    <Box paddingTop='1.5rem' margin='0 1.125rem'>
      {selectedEvent && (
        <EventHeader
          event={selectedEvent}
          goBackLink={`/event/${selectedEvent?.id}`}
          subtitle='Check-In | Ready to scan.'
          isMultipleEvents={isMultipleEvents}
        />
      )}
      <Divider />

      <Box display='flex' paddingTop='0.5rem' textAlign='center' justifyContent='center' flexDirection='column'>
        <Box>
          {!loading && orderTicket && <SuccessNotification goToScannedEvent={goToScannedEvent} label={label} />}
          {errorMessage && (
            <Typography color='error' className={classes.padding}>
              {errorMessage}
            </Typography>
          )}
        </Box>
        {!isEventInAccessPackage(accessPackages, eventId) && (
          <Button
            variant='outlined'
            onClick={confirmCheckIn}
            fullWidth
            style={{
              background: checkin && !checkInEntireOrder && totalTicketsToScan >= 1 ? 'skyblue' : 'transparent',
            }}
            className={classes.checkInButton}
            disabled={checkin && !checkInEntireOrder && totalTicketsToScan >= 1 ? false : true}
          >
            <InfoIcon fontSize='small' className={classes.infoIcon} />
            {'Check in all tickets'}
          </Button>
        )}
        <KeyboardReader onKeyboardReaderEnter={checkinScanFirstTime} />
      </Box>
      <ConfirmationModal
        title='Check In All Tickets'
        description={`Do you want to Check In ${totalTicketsToScan} tickets? `}
        onConfirmCallback={scanAllTickets}
      />
      <LoaderModal loading={loading && !offlineMode} />
      <CheckInOverrideModal open={overrideModalOpen} closeModal={() => setOverrideModalOpen(false)} />
    </Box>
  );
};
