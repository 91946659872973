import React, { FC, MouseEventHandler } from 'react';
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store';
import { useDispatch } from 'react-redux';
import { userLogout } from '../../store/slices/user-slice';
import pkg from '../../../package.json';
import { OffLineIcon } from '../../assets';
import useInternet from '../../hooks/internet-hook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      flexGrow: 1,
      marginBottom: 56,
    },
    themeHeader: {
      backgroundColor: theme.palette.headerBackground,
      color: theme.palette.getContrastText(theme?.palette?.headerBackground || '#000000'),
    },
    badgeWrapper: {
      marginLeft: theme.spacing(2),
    },
    badge: {
      backgroundColor: theme.palette.success.main,
      borderRadius: 10,
      width: '16px',
      height: '16px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    badgeIcon: {
      width: '13.33px',
      height: '13.33px',
      color: '#ffffff',
    },
    avatar: {
      background: theme.palette.info.main,
      width: 33,
      height: 33,
      border: '1px solid #E0E0E0',
    },
    accountButton: {
      padding: 0,
    },
    logo: {
      maxHeight: 44,
      maxWidth: 160,
    },
    offline: {
      '& path': {
        fill: '#DC2626',
      },
    },
  }),
);

export const Header: FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { hasInternet } = useInternet();
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & Element) | null>(null);

  const { userName } = useAppSelector((state) => state.user);
  const { logoUrl, siteName } = useAppSelector((state) => state.settings);

  const handleUserMenu: MouseEventHandler = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.appBar}>
      <AppBar position='fixed' className={classes.themeHeader}>
        <Toolbar>
          <Box display='flex' alignItems='center'>
            {logoUrl ? (
              <img src={logoUrl} className={classes.logo} alt='logo' />
            ) : (
              <Typography variant='h5'>{siteName}</Typography>
            )}
            {!hasInternet && <OffLineIcon className={classes.offline} />}
          </Box>

          <Box display='flex' justifyContent='flex-end' alignItems='center' flexGrow={2}>
            <Typography variant='h5'>Check-In</Typography>

            <div className={classes.badgeWrapper}>
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                overlap='circular'
                badgeContent={
                  <div className={classes.badge}>
                    <StarIcon fontSize='small' className={classes.badgeIcon} />
                  </div>
                }
              >
                <IconButton onClick={handleUserMenu} className={classes.accountButton}>
                  <Avatar className={classes.avatar}>{userName?.toUpperCase()}</Avatar>
                </IconButton>
              </Badge>
            </div>
          </Box>
          <Menu
            id='menu-appbar'
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={anchorEl !== null}
            onClose={handleClose}
          >
            <MenuItem component={Link} to='/about'>
              <Typography variant='subtitle1'>Premiere v{pkg.version}</Typography>
            </MenuItem>
            <MenuItem
              component={Link}
              onClick={() => {
                handleClose();
                dispatch(userLogout());
              }}
              to='/'
            >
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};
