import React, { FC } from 'react';
import { Box, createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { generateTheme } from '../../model/theme';
import { ThemeSettings } from '../../model/theme-settings';
import { Header } from '../display/header';
import { useAppSelector } from '../../store';

interface LayoutProps {
  themeSettings: ThemeSettings;
}

export const Layout: FC<LayoutProps> = ({ themeSettings, children }) => {
  const generatedTheme = generateTheme(themeSettings);
  const theme = createTheme(generatedTheme);
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  return (
    <Box id='layout' display='flex' flexDirection='column' justifyContent='space-between' height='100vh'>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isAuthenticated && <Header />}
        <Box display='flex' width='100%' justifyContent='center' height='calc(100% - 56px)'>
          <Box width='100%'>
            <Box className='content' sx={{ height: '100%' }}>
              {children}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
};

export default Layout;
