import { Event } from '../../model/tsp/event';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenericMessage } from '../../model/constants';
import { findEvents, findLimitedEvents } from '../thunks/event-thunks';

export type EventState = {
  events: Event[];
  limitedEvents: Event[];
  isMultipleEvents: boolean;
  toScan: Event[];
  selectedEvent: Event | null;
  loading: boolean;
  errorMessage: string | null;
};

const initialState: EventState = {
  events: [],
  limitedEvents: [],
  isMultipleEvents: false,
  toScan: [],
  selectedEvent: null,
  loading: false,
  errorMessage: null,
};

export const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    selectEvent: (state, { payload }: PayloadAction<string>) => {
      state.selectedEvent = state.limitedEvents.find((ev) => ev.id === payload) || null;
    },
    toggleIsMultipleEvents: (state) => {
      state.isMultipleEvents = !state.isMultipleEvents;
      state.toScan = [];
    },
    addOrRemoveEventToScan: (state, { payload }: PayloadAction<string>) => {
      if (state.toScan.map((event) => event.id).includes(payload)) {
        state.toScan = state.toScan.filter((event) => event.id !== payload);
      } else {
        const event: Event = state.events.find((event) => event.id === payload) || ({} as Event);
        state.toScan = [...state.toScan, event];
      }
    },
    setEventToScan: (state, { payload }: PayloadAction<string>) => {
      const event: Event = state.limitedEvents.find((event) => event.id === payload) || ({} as Event);
      state.toScan = [event];
    },
  },
  
  extraReducers: (builder) => {
    builder.addCase(findEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findEvents.fulfilled, (state, { payload }) => {
      state.events = payload;
      state.loading = false;
    });
    builder.addCase(findEvents.rejected, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
    builder.addCase(findLimitedEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findLimitedEvents.fulfilled, (state, { payload }) => {
      state.limitedEvents = payload;
      state.loading = false;
    });
    builder.addCase(findLimitedEvents.rejected, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
  },
});

export default eventSlice.reducer;

// ------------ ACTIONS ------------

export const { selectEvent, toggleIsMultipleEvents, addOrRemoveEventToScan, setEventToScan } = eventSlice.actions;
