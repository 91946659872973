import { Button, FilledInputProps, Grid, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import LinkIcon from '@material-ui/icons/Link';
import React, { FC } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FONT_BOLD, LocalStorageConf } from '../../model/constants';

const useStyles = makeStyles({
  textField: {
    border: '1px solid #1F3C8B',
    borderRadius: 4,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&.Mui-focused': {
      backgroundColor: '#fff',
    },
  },
  buttonText: {
    fontFamily: FONT_BOLD,
    fontSize: '0.875rem',
  },
  button: {
    marginTop: '2px',
    backgroundColor: '#1F3C8B',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1F3C8B',
    },
  },
  icon: {
    fill: 'rgba(0, 0, 0, 0.54)',
  },
});

interface LoginInputForm {
  username: string;
  password: string;
  apiUrl: string;
}

interface LoginFormProps {
  login: (userName: string, password: string, apiUrl: string) => Promise<void>;
}

export const LoginForm: FC<LoginFormProps> = ({ login }) => {
  const { handleSubmit, control } = useForm<LoginInputForm>({
    defaultValues: {
      username: '',
      password: '',
      apiUrl: localStorage.getItem(LocalStorageConf.ApiUrl) || '',
    },
  });

  const classes = useStyles();

  const onSubmit = (data) => {
    return login(data.username, data.password, data.apiUrl);
  };

  return (
    <form id='login-form' onSubmit={handleSubmit(onSubmit)}>
      <div className='login-form'>
        <Grid container>
          <Grid item xs={12}>
            <Controller
              name='username'
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  type='text'
                  size='small'
                  variant='filled'
                  label='Username'
                  fullWidth
                  InputProps={
                    {
                      classes: {
                        root: classes.textField,
                      },
                      disableUnderline: true,
                      required: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <PersonIcon className={classes.icon} />
                        </InputAdornment>
                      ),
                    } as Partial<FilledInputProps>
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='password'
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  type='password'
                  size='small'
                  variant='filled'
                  label='Password'
                  fullWidth
                  InputProps={
                    {
                      classes: {
                        root: classes.textField,
                      },
                      disableUnderline: true,
                      required: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <LockIcon className={classes.icon} />
                        </InputAdornment>
                      ),
                    } as Partial<FilledInputProps>
                  }
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name='apiUrl'
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  size='small'
                  variant='filled'
                  label='Site URL'
                  fullWidth
                  InputProps={
                    {
                      classes: {
                        root: classes.textField,
                      },
                      disableUnderline: true,
                      required: true,
                      endAdornment: (
                        <InputAdornment position='end'>
                          <LinkIcon className={classes.icon} />
                        </InputAdornment>
                      ),
                    } as Partial<FilledInputProps>
                  }
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant='contained'
              size='medium'
              type='submit'
              color='primary'
              fullWidth
              className={classes.button}
            >
              <Typography className={classes.buttonText}>Log In</Typography>
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
