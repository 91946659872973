import React from 'react';
import { Box, Modal, makeStyles, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
  loaderBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'rgba(0, 0, 0, 0.7)',
    color: '#ffffff',
    'border-radius': '10px',
    textAlign: 'center',
  },
  loaderText: {
    marginBottom: '1.5rem',
  },
}));

interface LoaderModalProps {
  loading: boolean;
  text?: string;
}

export const LoaderModal = (props: LoaderModalProps) => {
  const classes = useStyles();

  return (
    <Modal open={props.loading === true}>
      <Box className={classes.loaderBox} p='1.25rem'>
        {props.text !== undefined && (
          <Typography className={classes.loaderText} variant='body1' color='secondary'>
            {props.text}
          </Typography>
        )}
        <CircularProgress color='inherit' />
      </Box>
    </Modal>
  );
};
