import { Box, Checkbox, makeStyles, Typography } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { FONT_BOOK } from '../../model/constants';
import { useAppSelector } from '../../store';
import { selectSelectedTicketTypes, selectTicketTypes } from '../../store/slices/selectors';
import { selectTicketType } from '../../store/slices/ticket-slice';
import { retrieveTicketsTypes } from '../../store/thunks/ticket-thunks';
import ButtonIcon from '../display/button-icon';

const useStyles = makeStyles(() => ({
  container: {
    bgcolor: 'yellow',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '1rem',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  ticketsTypesContainer: {
    margin: '1rem 0',
    height: '100%',
    overflow: 'auto',
  },
  typesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    margin: '4px',
  },
  typeFont: {
    fontFamily: FONT_BOOK,
    fontSize: '16px',
  },
}));

export const TicketsTypesPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { eventId } = useParams<{ eventId: string }>();
  const dispatch = useDispatch();
  const eventTicketTypes = useAppSelector(selectTicketTypes);
  const eventSelectedTicketTypes = useAppSelector(selectSelectedTicketTypes);

  const ticketTypes = eventTicketTypes[eventId];
  const selectedTicketTypes = (eventSelectedTicketTypes[eventId] || []).map((selected) => selected.id);

  useEffect(() => {
    dispatch(retrieveTicketsTypes(eventId));
  }, [eventId]);

  const selectingType = (type) => {
    dispatch(selectTicketType({ eventId, ticketType: type }));
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.titleContainer}>
        <Typography>Select tickets to scan</Typography>
        <Box onClick={() => history.goBack()}>X</Box>
      </Box>
      <Box className={classes.ticketsTypesContainer}>
        {ticketTypes?.map((type) => (
          <Box onClick={() => selectingType(type)} key={type.id} className={classes.typesContainer}>
            <Checkbox checked={selectedTicketTypes.includes(type.id)} name='checkedB' color='primary' />
            <Typography className={classes.typeFont}>{type.name}</Typography>
          </Box>
        ))}
      </Box>
      <Box>
        <ButtonIcon
          label='Select Tickets'
          btnStyle={{ color: 'rgba(33, 150, 243, 1)', borderColor: 'rgba(33, 150, 243, 1)', marginTop: '0px' }}
          onClick={() => history.goBack()}
        />
      </Box>
    </Box>
  );
};
