import { db } from '.';
import { Ticket } from '../../model/tsp/ticket';
import { ReducedTicketType } from '../../model/tsp/ticket-type';

export class TicketsService {
  getAllTickets(): Promise<Ticket[]> {
    return db.transaction('r', db.tickets, async () => {
      return await db.tickets.toArray();
    });
  }

  getTicketByEvent(eventId: string): Promise<Ticket[]> {
    return db.transaction('r', db.tickets, async () => {
      return await db.tickets.where({ eventId }).toArray();
    });
  }

  getTicketsByOrderId(orderId: string): Promise<Ticket[]> {
    return db.transaction('r', db.tickets, async () => {
      return await db.tickets.where({ group: orderId }).toArray();
    });
  }

  getTicketByBarcode(barcode: string): Promise<Ticket | undefined> {
    return db.transaction('r', db.tickets, async () => {
      return await db.tickets.where({ barcode }).first();
    });
  }

  updateTicket(ticket: Ticket): Promise<number> {
    return db.transaction('rw', db.tickets, async () => {
      return await db.tickets.update(ticket.id, ticket);
    });
  }

  upddateTickets = (tickets: Ticket[]) => {
    return db.transaction('rw', db.tickets, async () => {
      return await db.tickets.bulkPut(tickets);
    });
  };

  findTicketTypesByEventId(eventId: string): Promise<ReducedTicketType[]> {
    return db.transaction('r', db.offlineTicketTypes, async () => {
      return await db.offlineTicketTypes.where({ eventId }).toArray();
    });
  }
}
