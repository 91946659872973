import { Box, makeStyles, Typography } from '@material-ui/core';
import { CrossRed2Icon, SyncBlueIcon } from '../../../assets';
import React, { FC } from 'react';
import { PendingTicket } from '../../../service/db-service';
import { useAppSelector } from '../../../store';
import { useDispatch } from 'react-redux';
import { removePendingTickets, syncPendingTicket } from '../../../store/thunks/ticket-thunks';

const useStyles = makeStyles(() => ({
  errorContainer: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
  },
  errorMessage: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: '#F44336',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336',
    border: '1px solid',
    margin: '0.5rem 1.5rem',
    padding: '0.5rem',
  },
  errorSyncActions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1.5rem',
  },
}));

interface PendingTicketsErrorProps {
  pending: PendingTicket;
}

const PendingTicketsError: FC<PendingTicketsErrorProps> = ({ pending }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loading } = useAppSelector((state) => state.ticket);

  return (
    <Box className={classes.errorContainer}>
      <Box className={classes.errorMessage}>
        <Typography variant='body2'>{pending.message}</Typography>
        <Box className={classes.errorSyncActions}>
          <button disabled={loading} onClick={() => dispatch(syncPendingTicket(pending))}>
            <SyncBlueIcon />
          </button>
          <button disabled={loading} onClick={() => dispatch(removePendingTickets(pending))}>
            <CrossRed2Icon />
          </button>
        </Box>
      </Box>
    </Box>
  );
};

export default PendingTicketsError;
