import { createSlice } from '@reduxjs/toolkit';
import { GenericMessage } from '../../model/constants';
import { AccessPackage } from "../../model/tsp/access-package";
import { findAccessPackages } from "../thunks/access-package-thunks";

export type AccessPackageState = {
  accessPackages: AccessPackage[];
  loading: boolean;
  errorMessage: string | null;
};

const initialState: AccessPackageState = {
  accessPackages: [],
  loading: false,
  errorMessage: null,
};

export const accessPackageSlice = createSlice({
  name: 'access-package',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findAccessPackages.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findAccessPackages.fulfilled, (state, { payload }) => {
      state.accessPackages = payload;
      state.loading = false;
    });
    builder.addCase(findAccessPackages.rejected, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
  },
});

export default accessPackageSlice.reducer;
