import { Box, Button, LinearProgress, makeStyles, Modal, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store';
import { selectEventsToScan, selectLoadingSyncToOnline } from '../../store/selectors';
import { showOnlineModal } from '../../store/slices/settings-slice';
import { findEvents } from '../../store/thunks/event-thunks';
import { setOnlineMode } from '../../store/thunks/settings-thunks';

const useStyles = makeStyles({
  modalContent: {
    position: 'absolute',
    top: '40%',
    margin: '0 1rem',
    background: '#ffffff',
    'border-radius': '4px',
    textAlign: 'center',
    width: '92%',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
});

interface OnlineModalProps {
  open: boolean;
  description: string;
}

const OnlineModal: FC<OnlineModalProps> = ({ open, description }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const toScan = useAppSelector(selectEventsToScan);
  const loadingSyncToOnline = useAppSelector(selectLoadingSyncToOnline);

  const onBackToOnline = async () => {
    await dispatch(setOnlineMode(toScan.map((event) => event.id)));
    await dispatch(showOnlineModal(false));
    dispatch(findEvents({ search: '' }));
  };

  return (
    <Modal open={open}>
      <Box className={classes.modalContent} p='1.25rem'>
        <Typography variant='h6' gutterBottom={true}>
          Go back to online mode?
        </Typography>
        {loadingSyncToOnline && (
          <Box marginTop='2.5rem' marginBottom='2rem'>
            <LinearProgress color='secondary' />
          </Box>
        )}
        {!loadingSyncToOnline && (
          <Box marginTop='2.5rem' marginBottom='2rem'>
            {description}
          </Box>
        )}
        <Button
          className={classes.marginBottom}
          variant='contained'
          color='primary'
          fullWidth={true}
          disabled={loadingSyncToOnline}
          onClick={onBackToOnline}
        >
          Back to online mode
        </Button>
        <Button
          variant='contained'
          color='secondary'
          fullWidth={true}
          onClick={() => dispatch(showOnlineModal(false))}
          disabled={loadingSyncToOnline}
        >
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default OnlineModal;
