import { useEffect, useState } from 'react';

export default function useInternet() {
  const [hasInternet, setHasInternet] = useState<boolean>(window.navigator.onLine);

  useEffect(() => {
    window.addEventListener('online', () => setHasInternet(true));
    window.addEventListener('offline', () => setHasInternet(false));
  }, []);

  return { hasInternet };
}
