import { Ticket } from './../model/tsp/ticket';

export const showTicketAttendeeName = (ticket: Ticket) => {
  if (!ticket) {
    return '-';
  }
  if (ticket.partyMember) {
    return `${ticket.partyMember} ${ticket.partyMemberLastName}`;
  } else if (ticket.firstName) {
    return `${ticket.firstName} ${ticket.lastName}`;
  } else {
    return `${ticket.billing_firstName} ${ticket.billing_lastName}`;
  }
};
