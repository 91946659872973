import { ProcessState } from './../model/tsp/order';
import { differenceInCalendarYears, parse } from 'date-fns';
import { Order } from '../model/tsp/order';

export const countValidTickets = (order: Order): number => {
  let unredeemedTickets = 0;

  order.tickets.data.forEach((ticket) => {
    if (!ticket.cancelled && ticket.processState !== ProcessState.Cancelled && parseInt(ticket.availableScans) > 0) {
      unredeemedTickets++;
    }
  });

  return unredeemedTickets;
};

export const isOrderCheckedIn = (order): boolean => countValidTickets(order) === order.tickets.data.length;

export const calculateAge = (year: string, month: string, day: string): string => {
  const date = parse(`${day}/${month}/${year}`, 'dd/MM/yyyy', new Date());
  return differenceInCalendarYears(new Date(), date).toString();
};

// matching
export const isMatching = (text: string, value: string): boolean => !!text.match(new RegExp(value, 'i'));
