import { AccessPackage } from "../model/tsp/access-package";
import { EventTicketType } from "../model/tsp/ticket-type";
import {Ticket} from "../model/tsp/ticket";

const isEventRetrieved = (eventsLastUpdate: EventTicketType[], eventId: string) => {
  return eventsLastUpdate[eventId] !== undefined;
}

const inAccessPackage = (ticket: Ticket) => {
  return parseInt(ticket.accessPackageId) > 0;
}

const isTicketInPackage = (accessPackages: AccessPackage[], eventIds: string[], ticket: Ticket) => {
  // TODO: Review multiple events flow in case that more than one selected events belong to the package
  if(inAccessPackage(ticket)){
    const accessPackage = accessPackages.find(ap => ap.id === ticket.accessPackageId);
    if (accessPackage) {
      const limit = accessPackage.limits.find(ap => eventIds.includes(ap.eventId) );
      if (limit) {
        return true;
      }
    }
  }
  return false;
}

const isEventInAccessPackage = (accessPackages: AccessPackage[], eventId: string) => {
  const packagesByEvent = getPackagesByEvent(accessPackages, eventId);
  return !!packagesByEvent.length;
}

const getOthersEventsInPackageToRetrieve = (
  eventsLastUpdate: EventTicketType[],
  accessPackages: AccessPackage[],
  eventIds: string[]
) => {
  const othersEventsInPackage = getOthersEventsInPackage(accessPackages, eventIds);
  return Object.values(othersEventsInPackage).filter((eventId) => !isEventRetrieved(eventsLastUpdate, eventId));
}

const getOthersEventsInPackage = (accessPackages: AccessPackage[], eventIds: string[]) => {
  const othersEvents: { [eventId: string]: string } = {};
  eventIds.forEach(eventId => {
    const packagesByEvent = getPackagesByEvent(accessPackages, eventId);
    packagesByEvent.forEach(({ limits }) => {
      const isInPackage = limits.some(limit => limit.eventId === eventId);
      if (isInPackage) {
        const othersEventsByPackage = limits.filter(limit => limit.eventId !== eventId)
        othersEventsByPackage.forEach(({eventId}) => othersEvents[eventId] = eventId);
      }
    });
  })

  return othersEvents;
}

const getPackagesByEvent = (accessPackages: AccessPackage[], eventId: string): AccessPackage[] => {
  return accessPackages.filter(({ limits }) => limits.find(limit => limit.eventId === eventId));
}

const getPackageById = (accessPackages: AccessPackage[], packageId: string): AccessPackage => {
  return accessPackages.find(ap => ap.id === packageId);
}

const getTicketsScannedByPackageEvent = (ticket: Ticket, eventId: string) => {
  return ticket.accessPackageScans.filter((aps) => aps.eventId === eventId) || [];
}

const getUsedScansAndAvailable = (
  accessPackages: AccessPackage[],
  ticket: Ticket,
  eventId: string
) => {
  let { usedScans, availableScans } = ticket;
  if (!inAccessPackage(ticket)) {
    return { usedScans, availableScans };
  }
  const accessPackage = getPackageById(accessPackages, ticket.accessPackageId);
  if (accessPackage) {
    usedScans = getTicketsScannedByPackageEvent(ticket, eventId).length.toString();
    availableScans = (accessPackage.limits.find(limit => limit.eventId === eventId)?.scans || 0).toString();
  }
  return { usedScans, availableScans };
}

const getEventInPackageWithAvailable = (
  accessPackages: AccessPackage[],
  ticket: Ticket,
  eventIds: string[]
) => {
  let { eventId } = ticket;
  const accessPackage = getPackageById(accessPackages, ticket.accessPackageId);
  if (inAccessPackage(ticket) && accessPackage) {
    eventIds.forEach(id => {
      const availableScans = (accessPackage.limits.find(limit => limit.eventId === id)?.scans || 0);
      if (availableScans > 0) {
        eventId = id;
      }
    })
  }
  return eventId;
}

export {
  getPackagesByEvent,
  getOthersEventsInPackage,
  getOthersEventsInPackageToRetrieve,
  getUsedScansAndAvailable,
  inAccessPackage,
  isEventInAccessPackage,
  isTicketInPackage,
  getEventInPackageWithAvailable,
};
