import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { TicketCountIcon } from '../../assets';

const useStyles = makeStyles((theme) => ({
  icon: {
    fill: theme.palette.primary.main,
  },
  count: {
    fontSize: '0.625rem',
    color: theme.palette.primary.contrastText,
    position: 'absolute',
    height: '0.8125rem',
    marginRight: '0.1rem',
  },
}));

interface TicketCountProps {
  count: number;
}

export const TicketCount = ({ count }: TicketCountProps) => {
  const classes = useStyles();

  return (
    <Box display='flex' justifyContent='center' alignItems='center' marginRight='0.75rem'>
      <TicketCountIcon className={classes.icon} />
      <Typography variant='subtitle2' className={classes.count}>
        {count}
      </Typography>
    </Box>
  );
};
