import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles(() => ({
  valueText: {
    wordBreak: 'break-all',
  },
}));

interface OrderTicketItemProps {
  label: string;
  value: string;
}

export const OrderTicketItem: FC<OrderTicketItemProps> = ({ label, value }) => {
  const classes = useStyles();
  if (!value || value === 'NaN') {
    return null;
  }
  return (
    <Grid item xs={6}>
      <Typography variant='caption'>{label}</Typography>
      <Typography variant='body1' className={classes.valueText}>
        {value}
      </Typography>
    </Grid>
  );
};
