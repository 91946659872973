import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, makeStyles, Modal, Slider, Theme, Typography, withStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { QRCode } from 'jsqr';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { FONT_BOLD, OrderMessage } from '../../model/constants';
import { CheckInOverrideModal } from '../display/check-in-override-modal';
import { fixDateIfApply } from '../../util/date-utils';
import { EventCalendar } from '../display/event-calendar';
import { LoaderModal } from '../display/loader-modal';
import { TicketScanner } from '../display/ticket-scanner';
import { selectEvent } from '../../store/slices/event-slice';
import { useAppSelector } from '../../store';
import { cleanCheckin, setErrorMessage } from '../../store/slices/ticket-slice';
import { checkinTicket } from '../../store/thunks/ticket-thunks';
import { PlayFunction } from 'use-sound/dist/types';
import {
  selectAccessPackages,
  selectEventsToScan,
  selectIsMultipleEvents,
  selectSelectedEvent
} from '../../store/selectors';
import SuccessNotification from '../display/success-notification';
import { MAX_TICKETS_ON_WHITELIST } from '../../util/constants';
import { openModal } from '../../store/slices/modalSlice';
import InfoIcon from '@material-ui/icons/Info';
import ConfirmationModal from '../display/confirmation-modal';
import { ProcessState } from '../../model/tsp/order';
import {Ticket} from "../../model/tsp/ticket";
import {
  getEventInPackageWithAvailable,
  getUsedScansAndAvailable,
  inAccessPackage,
  isEventInAccessPackage
} from "../../util/access-package-utils";
import { selectSelectedTicketTypes } from '../../store/slices/selectors';

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    marginTop: '1.25rem',
    marginBottom: '0.625rem',
    fontFamily: FONT_BOLD,
  },
  openButton: {
    backgroundColor: theme.palette.info.main,
  },
  controlBox: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#ffffff',
    position: 'absolute',
    padding: '1.25rem',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.54)',
    width: '100%',
  },
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  arrowIconWhite: {
    color: '#ffffff',
    fontSize: '0.9375rem',
  },
  modalBox: {
    width: '100%',
    maxWidth: '25rem',
    background: 'rgba(0, 0, 0, 0.7)',
    color: '#ffffff',
    'border-radius': '10px',
  },
  modalPosition: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
  },
  modalTicket: {
    position: 'absolute',
    width: '100%',
  },
  checkInButton: {
    border: '1px solid rgba(0, 0, 0, 0.87)',
    fontFamily: FONT_BOLD,
    textTransform: 'initial',
    background: 'gray',
  },
  infoIcon: {
    marginRight: '0.25rem',
  },
  successMessage: {
    display: 'flex',
    justifyContent: 'center',
    color: 'green',
    fontWeight: 'bold',
    fontSize: '2rem',
  },
}));

const ZoomSlider = withStyles({
  root: {
    color: '#0A84FF',
    height: 2,
    margin: '0 0.5rem',
  },
  thumb: {
    width: 28,
    height: 28,
    backgroundColor: '#ffffff',
    marginTop: -14,
    marginLeft: -8,
  },
})(Slider);

interface ScanPageProps {
  playSuccess: PlayFunction;
  playError: PlayFunction;
}

export const ScanPage: FC<ScanPageProps> = ({ playError, playSuccess }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedOrder = useAppSelector((state) => state.order.selectedOrder);
  const selectedEvent = useAppSelector(selectSelectedEvent);
  const isMultipleEvents = useAppSelector(selectIsMultipleEvents);
  const { checkin, errorMessage, loading } = useAppSelector((state) => state.ticket);
  const toScan = useAppSelector(selectEventsToScan);
  const accessPackages = useAppSelector(selectAccessPackages);
  const [allTicketsToScan, setAllTicketsToScan] = useState<Ticket[]>([]);
  const [totalTicketsToScan, setTotalTicketsToScan] = useState<number>(0);
  const eventSelectedTicketTypes = useAppSelector(selectSelectedTicketTypes);
  const [videoHeight, setVideoHeight] = useState<number>(100);
  const [overrideModalOpen, setOverrideModalOpen] = useState(false);
  const [successNotification, setSuccessNotification] = useState(false);
  const [recentScan, setRecentScan] = useState<string[]>([]);
  const apiLoading = useRef<boolean>(false);
  const [checkInEntireOrder, setCheckInEntireOrder] = useState(false);
  const [playedSuccess, setPlayedSuccess] = useState(false);
  const [barCodeSelected, setBarCodeSelected] = useState('');
  let ticketName = '';
  let orderTicket;
  let scanned = '';

  const { eventId } = useParams<{ eventId: string }>();

  useEffect(() => {
    dispatch(selectEvent(eventId));
  }, [eventId]);

  useEffect(() => {
    dispatch(cleanCheckin());

    return () => {
      dispatch(cleanCheckin());
    };
  }, []);

  useEffect(() => {
    if (selectedOrder) {
      const orderTicket = selectedOrder?.tickets?.data?.find((ot) => ot.barcode === barCodeSelected);
      if (orderTicket && inAccessPackage(orderTicket)) {
        const otherPendingTickets = [];
        const otherTickets = selectedOrder?.tickets?.data?.filter((ot) => ot.barcode !== barCodeSelected);
        otherTickets.forEach(ticket => {
          const { usedScans, availableScans } = getUsedScansAndAvailable(accessPackages, ticket, eventId);
          if(availableScans != usedScans){
            otherPendingTickets.push(ticket);
          }
        })
        setAllTicketsToScan(otherPendingTickets);
        setTotalTicketsToScan(otherPendingTickets.length);
      } else {
        const ticketsToScan = selectedOrder?.tickets.data.filter(
          (ticket) =>
            ticket.processState === ProcessState.Purchased
            && parseInt(ticket.availableScans) !== parseInt(ticket.usedScans),
        )
        setAllTicketsToScan(ticketsToScan);
        setTotalTicketsToScan(ticketsToScan?.filter(ticket => ticket.barcode !== barCodeSelected).length);
      }
    }
  }, [selectedOrder]);

  const checkinScanFirstTime = (barCode: string) => {
    setBarCodeSelected(barCode);
    checkinScan(barCode);
  };

  const scanAllTickets = async () => {
    try {
      if (allTicketsToScan) {
        for (const ticket of allTicketsToScan) {
          if (barCodeSelected !== ticket.barcode) {
            await multipleCheckinScan(ticket.barcode);
          }
        }
      }
      setBarCodeSelected('');
      setCheckInEntireOrder(true);
      setPlayedSuccess(false);
    } catch (error) {
      console.error('Error', error);
    }
  };

  const confirmCheckIn = () => {
    dispatch(openModal('scanAll'));
  };

  const checkinScan = useCallback(async (barCode: string) => {
    setRecentScan([...recentScan, barCode]);
    const selectedTicketTypes = (eventSelectedTicketTypes[eventId] || []).map((selected) => selected.id);

    const query = {
      barCode,
      eventIds: toScan.map((event) => event.id),
      occurrence: null,
      ticketTypeIds: selectedTicketTypes,
      isMultipleEvents: isMultipleEvents,
    };

    if (barCode.length > 0 && apiLoading.current === false && !errorMessage) {
      apiLoading.current = true;
      setOverrideModalOpen(false);

      dispatch(checkinTicket(query));
    }
    setCheckInEntireOrder(false);
    setPlayedSuccess(false);
  }, []);

  const multipleCheckinScan = useCallback(async (barCode: string) => {
    setRecentScan([...recentScan, barCode]);
    const selectedTicketTypes = (eventSelectedTicketTypes[eventId] || []).map((selected) => selected.id);

    const query = {
      barCode,
      eventIds: toScan.map((event) => event.id),
      occurrence: null,
      ticketTypeIds: selectedTicketTypes,
      isMultipleEvents: isMultipleEvents,
    };

    if (barCode.length > 0 && !errorMessage) {
      apiLoading.current = true;
      setOverrideModalOpen(false);

      dispatch(checkinTicket(query));
    }
  }, []);

  const onScan = useCallback(
    (code: QRCode): void => {
      const barCode = code.data;
      const isRecent = recentScan.find((code) => code === barCode);
      if (barCode.length > 0 && !isRecent) {
        checkinScanFirstTime(barCode);
      }
    },
    [checkinScanFirstTime, recentScan],
  );

  const onMediaError = (mediaError: string | DOMException) => dispatch(setErrorMessage(mediaError?.toString()));

  const handleSlider = (event: any, value: number | number[]) => {
    setVideoHeight((value as number) + 100);
  };

  if (errorMessage) {
    playError();
  }

  const goToScannedEvent = () => {
    history.push(`/event/${eventId}/order/${checkin.orderId}/barcode/${checkin.barcode}`);
  };

  const updateWhitelist = (barcode: string) => {
    const isFullWhitelist = recentScan.length >= MAX_TICKETS_ON_WHITELIST;
    if (isFullWhitelist) {
      const lastTickets = recentScan.slice(-MAX_TICKETS_ON_WHITELIST);
      return setRecentScan([...lastTickets, barcode]);
    }
    return setRecentScan([...recentScan, barcode]);
  };

  useEffect(() => {
    if (checkin?.valid && (!checkInEntireOrder || !playedSuccess)) {
      setSuccessNotification(true);
      updateWhitelist(checkin.barcode);
      playSuccess();
      setPlayedSuccess(true);
      apiLoading.current = false;
      setTimeout(() => {
        setSuccessNotification(false);
        setPlayedSuccess(false);
      }, 5000);
    }
  }, [checkin, playSuccess]);

  if (checkin?.valid) {
    orderTicket = selectedOrder?.tickets?.data?.find((ot) => ot.barcode === checkin?.barcode);
    if (orderTicket) {
      const eventId = getEventInPackageWithAvailable(accessPackages, orderTicket, toScan.map((event) => event.id));
      const { usedScans, availableScans } = getUsedScansAndAvailable(accessPackages, orderTicket, eventId);

      ticketName =
        orderTicket.firstName === 'Box' && orderTicket.lastName == 'Office'
          ? 'Box Office'
          : orderTicket.partyMember + ' ' + orderTicket.partyMemberLastName;

      if(orderTicket?.scanType === 'package' && availableScans && usedScans) {
        scanned = ` - ${usedScans}/${availableScans}`;
      }

      if (checkInEntireOrder) {
        ticketName = OrderMessage.EntireOrderChecked;
      }
    }
  }

  const label = checkInEntireOrder ? `${ticketName}` : `${ticketName} Checked In ${scanned}`;

  return selectedEvent !== null ? (
    <>
      <Box height={'calc(100vh - 56px)'} width='100vw' overflow='hidden' display='flex' justifyContent='center'>
        <TicketScanner onMediaError={onMediaError} onScan={onScan} videoHeight={videoHeight ? videoHeight : 100} />


        <Box className={classes.controlBox}>
          <Box width='100%'>
            <Link to={`/event/${eventId}`}>
              <Grid container alignItems='center'>
                <Grid item xs={1}>
                  <ArrowBackIosIcon className={classes.arrowIconWhite} />
                </Grid>
                <Grid item xs={2}>
                  <EventCalendar weekday={false} date={fixDateIfApply(new Date(selectedEvent.start * 1000))} />
                </Grid>
                <Grid item xs={9}>
                  <Typography variant='h6'>{isMultipleEvents ? 'Multiple Events' : selectedEvent.title}</Typography>
                  <Typography variant='subtitle2'>Check-In</Typography>
                </Grid>
              </Grid>
            </Link>
          </Box>
        </Box>
        {!loading && successNotification && (
          <Box bottom='13rem' className={classes.modalTicket}>
            <SuccessNotification goToScannedEvent={goToScannedEvent} label={label} />
          </Box>
        )}
        {!loading && !isEventInAccessPackage(accessPackages, eventId) && (
          <Box bottom='11rem' className={classes.modalTicket}>
            <Button
              variant='outlined'
              onClick={confirmCheckIn}
              fullWidth
              className={classes.checkInButton}
              style={{
                background: checkin && !checkInEntireOrder && totalTicketsToScan >= 1 ? 'skyblue' : 'transparent',
              }}
              disabled={checkin && !checkInEntireOrder && totalTicketsToScan >= 1 ? false : true}
            >
              <InfoIcon fontSize='small' className={classes.infoIcon} />
              {'Check in all tickets'}
            </Button>
          </Box>
        )}
        <Box height='178px' bottom='0' className={classes.controlBox}>
          <ZoomSlider value={videoHeight - 100} onChange={handleSlider} />
        </Box>
      </Box>
      <LoaderModal loading={loading} />
      <Modal
        open={errorMessage !== null}
        onClose={() => dispatch(setErrorMessage(null))}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box className={classes.modalPosition} p='3.25rem'>
          <Box className={classes.modalBox} px='1.25rem' py='1.875rem'>
            <Box mb='1.5rem'>
              <Typography align='center'>{errorMessage}</Typography>
            </Box>
            <Button
              className={classes.closeButton}
              fullWidth
              onClick={() => {
                dispatch(setErrorMessage(null));
                apiLoading.current = false;
              }}
              variant='contained'
            >
              Dismiss
            </Button>
          </Box>
        </Box>
      </Modal>
      <ConfirmationModal
        title='Check In All Tickets'
        description={`Do you want to Check In ${totalTicketsToScan} tickets? `}
        onConfirmCallback={scanAllTickets}
      />
      <CheckInOverrideModal
        open={overrideModalOpen}
        closeModal={() => {
          setOverrideModalOpen(false);
          apiLoading.current = false;
        }}
      />
    </>
  ) : null;
};
