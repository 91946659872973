import React, { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { useAppSelector } from '../store';
import Layout from './widget/layout';
import {
  AttendeesPage,
  CheckinSyncPage,
  EventDetailPage,
  LandingPage,
  LaserScanPage,
  LoginPage,
  NotFoundPage,
  OrderDetailPage,
  ScanPage,
  TicketsTypesPage,
} from './pages';
import { AuthRoute } from './widget/auth-route';
import useSound from 'use-sound';
import SyncMode from './widget/sync-mode';
import { ErrorWavSound, SuccessWavSound } from '../assets';
import { AboutPage } from './pages/settings-page';

const Routes: FC = () => {
  const { logoUrl, headerBackground, accentColor, siteName } = useAppSelector((state) => state.settings);

  const [playSuccess] = useSound(SuccessWavSound);
  const [playError] = useSound(ErrorWavSound);

  return (
    <BrowserRouter>
      <SyncMode>
        <Layout themeSettings={{ logoUrl, headerBackground, accentColor, siteName }}>
          <Switch>
            <AuthRoute exact path='/' child={<LandingPage />} />
            <Route path='/login'>
              <LoginPage />
            </Route>
            <AuthRoute exact path='/event/:id' child={<EventDetailPage />} />
            <AuthRoute path='/attendees/:eventId' child={<AttendeesPage />} />
            <AuthRoute path='/event/:eventId/ticket-types' child={<TicketsTypesPage />} />
            <AuthRoute path='/event/:eventId/order/:orderId/attendees' child={<OrderDetailPage />} />
            <AuthRoute path='/event/:eventId/order/:orderId/attendees' child={<OrderDetailPage />} />
            <AuthRoute path='/event/:eventId/order/:orderId/barcode/:barCode' child={<OrderDetailPage />} />
            <AuthRoute path='/scan/:eventId' child={<ScanPage playError={playError} playSuccess={playSuccess} />} />
            <AuthRoute path='/about' child={<AboutPage />} />
            <AuthRoute path='/checkin-sync' child={<CheckinSyncPage />} />
            <AuthRoute
              path='/laser-scan/:eventId'
              child={<LaserScanPage playError={playError} playSuccess={playSuccess} />}
            />
            <Route path='*'>
              <NotFoundPage />
            </Route>
          </Switch>
        </Layout>
      </SyncMode>
    </BrowserRouter>
  );
};

export default Routes;
