import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import rootReducer, { RootReducerType } from './rootReducer';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { setupPersistReducer } from './persistReducer';

const persistedReducer = setupPersistReducer(rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export type RootState = ReturnType<RootReducerType>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const getStoreWithState = (preloadedState?: RootState): AppThunk & AppDispatch & RootState =>
  // @ts-ignore
  configureStore({ reducer: rootReducer, preloadedState });

export default store;
