export const getHexColorCss = (color: string) => {
  const strColor = color.startsWith('#') ? color : `#${color}`;
  const reg = /^#([0-9a-f]{3}){1,2}$/i;
  if (reg.test(strColor)) {
    return strColor;
  } else {
    return '#000000';
  }
};

export const verifySiteUrl = (siteUrl: string): string =>
  /^(http|https)/.test(siteUrl.toLowerCase()) ? siteUrl : `https://${siteUrl}`;

export const waiting = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
