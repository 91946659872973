import { createAsyncThunk } from "@reduxjs/toolkit";
import { AccessPackage } from "../../model/tsp/access-package";
import { ApiError } from "../../model/common-types";
import { apiGetAccessPackages } from "../../service/api-service";
import { GenericMessage } from "../../model/constants";

export const findAccessPackages = createAsyncThunk<AccessPackage[], void, { rejectValue: ApiError }>(
  'loadAccessPackages',
  async (_: void, thunkApi) => {
    try {
      const res = await apiGetAccessPackages();
      if (res.success) {
        return res.data;
      } else {
        return thunkApi.rejectWithValue({ code: 400, message: res.message || GenericMessage.UnknownError });
      }
    } catch (error) {
      return thunkApi.rejectWithValue({
        code: 400,
        message: error?.message || GenericMessage.UnknownError,
      } as ApiError);
    }
  },
);