import React, {FC, useState} from 'react';
import {Box, Button, Divider, Grid, makeStyles, Typography} from '@material-ui/core';
import {Link, useHistory} from 'react-router-dom';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import pkg from '../../../package.json';
import ConfirmationModal from "../display/confirmation-modal";
import {openModal} from "../../store/slices/modalSlice";
import {useDispatch} from "react-redux";
import {userLogout} from "../../store/slices/user-slice";
import {checkinsDBService, db, PendingTicketState} from '../../service/db-service';
import {reduxReset} from "../../store/slices/settings-slice";
import {useAppSelector} from "../../store";
import {selectSelectedEvent} from "../../store/selectors";
import AlertModal from "../display/alert-modal";

const useStyles = makeStyles(() => ({
  arrowIcon: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '0.9375rem',
  },
  modalContent: {
    position: 'absolute',
    top: '40%',
    margin: '0 1rem',
    background: '#ffffff',
    'border-radius': '4px',
    textAlign: 'center',
    width: '92%',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
}));

export const AboutPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const event = useAppSelector(selectSelectedEvent);
  const [openAlert, setOpenAlert] = useState(false);

  const clearData = async () => {
    const pendings = [
      ...(await checkinsDBService.getPendingTicketsByState(PendingTicketState.PENDING)),
      ...(await checkinsDBService.getPendingTicketsByState(PendingTicketState.ERROR)),
    ];
    if(pendings.length > 0) {
      toogleAlertModal();
    } else {
      openConfirmModal();
    }
  };

  const toogleAlertModal = () => {
    setOpenAlert(!openAlert);
  }

  const openConfirmModal = () => {
    dispatch(openModal('clearAll'));
  }

  const goToSyncPage = () => {
    history.push(`/checkin-sync`);
  }

  const clearAllData = async () => {
    sessionStorage.clear();
    localStorage.clear();
    caches.keys().then(keys => {
      keys.forEach(key => caches.delete(key))
    });
    await db.resetDatabase();
    dispatch(userLogout());
    dispatch(reduxReset());
  }

  const closeGoToSyncModal = async () => {
    toogleAlertModal();
    goToSyncPage();
  }

  return (
    <>
      <Box paddingTop='1.5rem' margin='0 1.125rem'>
        <Box width='100%' paddingBottom='1.5rem'>
          <Box width='100%'>
            <Link to="/">
              <Grid container alignItems='center'>
                <Grid item xs={1}>
                  <ArrowBackIosIcon className={classes.arrowIcon} />
                </Grid>
                <Grid item xs={11}>
                  <Typography variant='h6'>About</Typography>
                  <Box display='flex' alignItems='center'>
                    <Typography variant='subtitle1'>Premiere v{pkg.version}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Link>
          </Box>
        </Box>
        <Divider />

        <Box display='flex' paddingTop='2rem' textAlign='center' justifyContent='center' flexDirection='column'>
          {!event && (
            <Typography color='error' align='center'>
              Please select an event to access the sync page
            </Typography>
          )}
          <Box paddingTop='1rem'>
            <Button
              variant='contained'
              color='secondary'
              fullWidth={true}
              onClick={goToSyncPage}
              disabled={!event}
            >
              Go to Sync Pages
            </Button>
          </Box>
          <Box paddingTop='1rem'>
            <Button
              variant='contained'
              color='primary'
              fullWidth={true}
              onClick={clearData}
            >
              Clean Data
            </Button>
          </Box>
        </Box>
      </Box>
      <ConfirmationModal
        title='Clear all data'
        description={`Do you want to clean all site data? `}
        onConfirmCallback={clearAllData}
      />
      <AlertModal
        open={openAlert}
        title='Go to Sync Page'
        description='It seems that some tickets are pending synchronization or have failed to sync. You will be redirected to the sync page to view details.'
        onCloseCallback={closeGoToSyncModal}
      />
    </>
  );
};
