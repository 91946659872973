import { GenericMessage } from '../../model/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Order } from '../../model/tsp/order';
import { findOrders, findOrdersById } from '../thunks/order-thunks';

export type OrderState = {
  orders: Order[];
  selectedOrder: Order | null;
  loading: boolean;
  errorMessage: string | null;
};

const initialState: OrderState = {
  orders: [],
  selectedOrder: null,
  loading: false,
  errorMessage: null,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    cleanOrders: (state) => {
      state.orders = [];
    },
    selectOrder: (state, { payload }: PayloadAction<string>) => {
      state.selectedOrder = state.orders.find((o) => o.id === payload) || null;
    },
    setSelectedOrder: (state, { payload }: PayloadAction<Order>) => {
      state.selectedOrder = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findOrders.pending, (state) => {
      state.orders = [];
      state.loading = true;
      state.errorMessage = null;
    });
    builder.addCase(findOrders.fulfilled, (state, { payload }) => {
      state.orders = payload;
      state.loading = false;
    });
    builder.addCase(findOrders.rejected, (state, { payload }) => {
      state.orders = [];
      state.loading = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
    builder.addCase(findOrdersById.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(findOrdersById.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.selectedOrder = payload;
    });
    builder.addCase(findOrdersById.rejected, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload?.message || null;
    });
  },
});

export default orderSlice.reducer;

// ------------ ACTIONS ------------

export const { cleanOrders, selectOrder, setSelectedOrder } = orderSlice.actions;
