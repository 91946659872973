import { Box, Button, LinearProgress, makeStyles, Modal, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../store';
import { selectIsOpenModal, selectModalLoading } from '../../store/selectors';
import { closeModal, startLoadingModal, stopLoadingModal } from '../../store/slices/modalSlice';

const useStyles = makeStyles({
  modalContent: {
    position: 'absolute',
    top: '40%',
    margin: '0 1rem',
    background: '#ffffff',
    'border-radius': '4px',
    textAlign: 'center',
    width: '92%',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
});

interface ConfirmationModalProps {
  title: string;
  description: string;
  onConfirmCallback: () => Promise<void>;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({ title, description, onConfirmCallback }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isOpened = useAppSelector(selectIsOpenModal);
  const loading = useAppSelector(selectModalLoading);

  return (
    <>
      <Modal open={isOpened}>
        <Box className={classes.modalContent} p='1.25rem'>
          <Typography variant='h6' gutterBottom={true}>
            {title}
          </Typography>
          <Box marginTop='2.5rem' marginBottom='2rem'>
            {description}
          </Box>
          {loading && (
            <Box marginTop='2.5rem' marginBottom='2rem'>
              <LinearProgress color='secondary' />
            </Box>
          )}
          <Button
            className={classes.marginBottom}
            variant='contained'
            color='primary'
            fullWidth={true}
            disabled={loading}
            onClick={async () => {
              dispatch(startLoadingModal());
              await onConfirmCallback();
              dispatch(stopLoadingModal());
              dispatch(closeModal());
            }}
          >
            Yes
          </Button>
          <Button
            variant='contained'
            color='secondary'
            fullWidth={true}
            onClick={() => {
              dispatch(closeModal());
            }}
            disabled={loading}
          >
            No
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default ConfirmationModal;
