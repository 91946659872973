/* eslint-disable sonarjs/no-duplicate-string */
import { ThemeOptions } from '@material-ui/core';
import { merge } from 'lodash';

import { ThemeSettings } from './theme-settings';
import { FONT_BOOK, FONT_BOLD } from './constants';

const baseThemeOptions: ThemeOptions = {
  palette: {
    secondary: {
      main: '#939393',
    },
    success: {
      main: '#51CF66',
    },
    warning: {
      main: '#FF9800',
    },
    divider: 'rgba(0, 0, 0, 0.25)',
    background: {
      default: '#EFEFEF',
    },
    contrastThreshold: 3,
  },
  typography: {
    fontFamily: FONT_BOOK,
    h4: {
      fontFamily: FONT_BOLD,
      fontSize: '2.25rem',
    },
    h5: {
      fontFamily: FONT_BOOK,
      fontSize: '1.3125rem',
    },
    h6: {
      fontFamily: FONT_BOLD,
      lineHeight: '1.2rem',
      fontSize: '1.125rem',
    },
    subtitle1: {
      fontFamily: FONT_BOLD,
      fontSize: '0.625rem',
      lineHeight: '1rem',
      textTransform: 'uppercase',
    },
    subtitle2: {
      fontFamily: FONT_BOLD,
    },
    body1: {
      fontFamily: FONT_BOOK,
    },
    caption: {
      fontFamily: FONT_BOLD,
    },
  },
  shape: { borderRadius: 4 },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
        textTransform: 'none',
        fontWeight: 700,
      },
    },
  },
};

export const generateTheme = (settings: ThemeSettings): ThemeOptions => {
  let brandTheme: ThemeOptions = {};
  if (settings !== null) {
    brandTheme = {
      palette: {
        primary: {
          main: settings.accentColor,
        },
        headerBackground: settings.headerBackground,
      },
    };
  }

  return merge(baseThemeOptions, brandTheme);
};
