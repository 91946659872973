import { Box, Button, makeStyles, Modal, Typography } from '@material-ui/core';
import React, {FC, ReactNode} from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../store/slices/modalSlice';

const useStyles = makeStyles({
  modalContent: {
    position: 'absolute',
    top: '40%',
    margin: '0 1rem',
    background: '#ffffff',
    'border-radius': '4px',
    textAlign: 'center',
    width: '92%',
  },
});

interface AlertModalProps {
  open: boolean;
  onCloseCallback: () => Promise<void>;
  title: string;
  description: string;
  details?: ReactNode;
}

const AlertModal: FC<AlertModalProps> = ({ title, description, details, open, onCloseCallback }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  return (
    <>
      <Modal open={open}>
        <Box className={classes.modalContent} p='1.25rem'>
          <Typography variant='h6' gutterBottom={true}>
            {title}
          </Typography>
          <Box marginTop='2rem' marginBottom='1rem'>
            {description}
          </Box>
          {details && (
            <Box marginBottom='1rem'>
              {details}
            </Box>
          )}
          <Button
            variant='contained'
            color='secondary'
            fullWidth={true}
            onClick={async () => {
              await onCloseCallback();
              dispatch(closeModal());
            }}
          >
            Ok
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AlertModal;
