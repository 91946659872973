import { endOfDay, isBefore, isToday } from 'date-fns';

/**
 * Given a date return if its less or equals of today.
 * @param date
 * @returns boolean
 */
export const lessOrEqualsThanToday = (date: Date): boolean => {
  return isToday(date) || isBefore(date, getToday());
};

/**
 * Return the current day at the end. Example: 23:59:59
 * @returns Date
 */
export const getToday = (): Date => {
  return endOfDay(new Date());
};

/**
 * Given a date than is less than current day returns the current date
 * @param date
 * @returns date
 */
export const fixDateIfApply = (date: Date): Date => {
  return lessOrEqualsThanToday(date) ? getToday() : date;
};
