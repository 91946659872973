import React, { FC } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { LoginForm } from '../display/login-form';
import { Redirect } from 'react-router-dom';
import { LogoIcon } from '../../assets';
import { userLogin } from '../../store/thunks/user-thunks';
import { useAppSelector } from '../../store';
import { verifySiteUrl } from '../../util/common-utils';

const useStyles = makeStyles((theme: Theme) => ({
  h4: {
    color: '#fff',
    marginTop: '1rem',
    fontFamily: 'Gotham Black',
    fontSize: '1.rem',
    letterSpacing: '-0.75',
  },
  error: {
    color: theme.palette.error.main,
  },
  thin: {
    fontWeight: 200,
    fontFamily: 'Gotham Book',
  },
  background: {
    background: '#254aaf',
  },
}));

export const LoginPage: FC = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const isAuthenticated = useAppSelector((state) => state.user.isAuthenticated);

  const { errorMessage } = useAppSelector((state) => state.user);

  const login = async (userName: string, password: string, apiUrl: string): Promise<void> => {
    dispatch(userLogin({ userName, password, apiUrl: verifySiteUrl(apiUrl) }));
  };

  return (
    <>
      {isAuthenticated ? (
        <Redirect to='/' />
      ) : (
        <div id='Login-page'>
          <Box
            display='flex'
            width='100%'
            alignItems='center'
            padding='2rem'
            flexDirection='column'
            textAlign='center'
            height='100vh'
            className={classes.background}
          >
            <LogoIcon />
            <Typography variant='h4' className={classes.h4}>
              Universal <span className={classes.thin}>| Check-In</span>
            </Typography>
            <Box marginTop='1rem'>
              <LoginForm login={login} />
              {errorMessage ? <Typography className={classes.error}>{errorMessage}</Typography> : null}
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};
