import { ticketsDBService } from '.';
import { Order, TicketObj } from '../../model/tsp/order';
import { Ticket } from '../../model/tsp/ticket';

export class OrdersService {
  async getOrderById(orderId: string): Promise<Order> {
    const tickets = await ticketsDBService.getTicketsByOrderId(orderId);
    const order = {} as Order;
    (order.id = orderId), (order.tickets = {} as TicketObj);
    order.tickets.data = tickets;
    return order;
  }

  filterArrId = (arr: string[]): string[] =>
    arr.reduce((prev: string[], curr: string) => {
      return prev.indexOf(curr) < 0 ? [...prev, curr] : prev;
    }, []);

  async transformTicketsToOrders(tickets: Ticket[]): Promise<Order[]> {
    const orderIds = tickets.map((ticket) => ticket.group);
    const pendings: Promise<Order>[] = [];
    this.filterArrId(orderIds).forEach((orderId) => pendings.push(this.getOrderById(orderId)));
    return Promise.all(pendings);
  }
}
