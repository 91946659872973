import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { Question } from '../../model/tsp/ticket';

interface QuestionsProps {
  title: string;
  questions: Question[];
}

export const QuestionItem: FC<{ question: Question }> = ({ question }) => (
  <Box key={question.questionId}>
    <b>{question.question}: </b>
    <span>{question.answerText}</span>
  </Box>
);

export const QuestionsView: FC<QuestionsProps> = ({ title, questions }) => {
  const list = questions?.filter((q) => q.answerText);
  if (!list || list.length === 0) {
    return null;
  }
  return (
    <Box paddingTop={2}>
      <Typography variant='caption'>{title}</Typography>
      {questions.map((qt, idx) => (
        <QuestionItem key={idx} question={qt} />
      ))}
    </Box>
  );
};
