import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GenericMessage } from '../../model/constants';
import { existsPendingsWithErrors, loadSettings, setOfflineMode, setOnlineMode } from '../thunks/settings-thunks';

export interface SettingsState {
  headerBackground: string;
  logoUrl: string;
  siteName: string;
  accentColor: string;
  loading: boolean;
  errorMessage: string | null;
  offlineMode: boolean;
  onlineModal: boolean;
  loadingSyncToOnline: boolean;
  syncMode: boolean;
  pendingTicketsCount: number;
  reset: boolean;
}

const initialState: SettingsState = {
  headerBackground: '#000000',
  logoUrl: '',
  siteName: '',
  accentColor: '#CCaaCC',
  loading: false,
  errorMessage: '',
  offlineMode: true,
  onlineModal: false,
  loadingSyncToOnline: false,
  syncMode: false,
  pendingTicketsCount: 0,
  reset: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleSyncMode: (state) => {
      state.syncMode = !state.syncMode;
    },
    showOnlineModal: (state, { payload }: PayloadAction<boolean>) => {
      state.onlineModal = payload;
    },
    reduxReset: (state) => {
      state.reset = true;
    },
    setLoadingManually: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSettings.pending, (state) => {
      state.loading = true;
      state.errorMessage = '';
    });
    builder.addCase(loadSettings.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.accentColor = payload.accentColor;
      state.logoUrl = payload.logoUrl;
      state.siteName = payload.siteName;
      state.headerBackground = payload.headerBackground;
    });
    builder.addCase(loadSettings.rejected, (state, { payload }) => {
      state.loading = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
    builder.addCase(setOfflineMode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setOfflineMode.fulfilled, (state) => {
      state.loading = false;
      state.offlineMode = true;
      state.syncMode = true;
    });
    builder.addCase(setOfflineMode.rejected, (state, { payload }) => {
      state.loading = false;
      state.offlineMode = true;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
    builder.addCase(setOnlineMode.pending, (state) => {
      state.loading = true;
      state.loadingSyncToOnline = true;
    });
    builder.addCase(setOnlineMode.fulfilled, (state) => {
      state.loading = false;
      state.offlineMode = true;
      state.syncMode = false;
      state.loadingSyncToOnline = false;
    });
    builder.addCase(setOnlineMode.rejected, (state, { payload }) => {
      state.loading = false;
      state.loadingSyncToOnline = false;
      state.errorMessage = payload?.message || GenericMessage.UnknownError;
    });
    builder.addCase(existsPendingsWithErrors.pending, (state) => {
      state.pendingTicketsCount = 0;
    });
    builder.addCase(existsPendingsWithErrors.fulfilled, (state, { payload }: PayloadAction<number>) => {
      state.pendingTicketsCount = payload;
    });
  },
});

export default settingsSlice.reducer;

// ------------ ACTIONS ------------

export const { toggleSyncMode, showOnlineModal, reduxReset, setLoadingManually } = settingsSlice.actions;
