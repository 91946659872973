import { Box, Button, makeStyles, Modal, Typography } from '@material-ui/core';
import React, { FC } from 'react';

const useStyles = makeStyles({
  modalContent: {
    position: 'absolute',
    top: '40%',
    margin: '0 1rem',
    background: '#ffffff',
    'border-radius': '4px',
    textAlign: 'center',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
});

interface CheckInOverrideModalProps {
  open: boolean;
  closeModal: () => void;
}

export const CheckInOverrideModal: FC<CheckInOverrideModalProps> = ({ open, closeModal }) => {
  const classes = useStyles();

  return (
    <Modal open={open}>
      <Box className={classes.modalContent} p='1.25rem'>
        <Typography variant='h6' gutterBottom={true}>
          Wrong Occurrence
        </Typography>
        <Box marginBottom='1rem'>
          This ticket is for a different occurrence. Would you like to check-in for that occurrence?
        </Box>
        <Button variant='contained' color='primary' fullWidth={true} onClick={() => closeModal()}>
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};
