import { Box, Typography } from '@material-ui/core';
import React from 'react';

export const NotFoundPage = () => {
  return (
    <>
      <Box padding='1rem'>
        <Typography variant='h4'>404</Typography>
      </Box>
    </>
  );
};
